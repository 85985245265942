import { parseISO, addHours } from "date-fns";

import { Text } from "@cruk/cruk-react-components";
import { isValidDate } from "@fwa/src/utils/timeUtils";
import { formatInTimeZone } from "date-fns-tz";

type Props = {
  name: string;
  birthDateString?: string | null;
  deathDateString?: string | null;
};

export const InMemory = ({
  name,
  birthDateString = null,
  deathDateString = null,
}: Props) => {
  const getLifeDatesString = (): string | null => {
    const birthDate = birthDateString
      ? addHours(parseISO(birthDateString), 12)
      : null;
    const deathDate = deathDateString
      ? addHours(parseISO(deathDateString), 12)
      : null;
    const birthValid = birthDate && isValidDate(birthDate);
    const deathValid = deathDate && isValidDate(deathDate);

    if (!birthDate || !deathDate || !birthValid || !deathValid) return null;
    return `${formatInTimeZone(
      birthDate,
      "Europe/London",
      "do LLL yyyy",
    )} - ${formatInTimeZone(deathDate, "Europe/London", "do LLL yyyy")}`;
  };

  return (
    <>
      <Text>In memory of</Text>
      <Text textSize="l">{name}</Text>
      {getLifeDatesString() ? <Text>{getLifeDatesString()}</Text> : null}
    </>
  );
};

export default InMemory;
