import { type HTMLAttributes } from "react";
import dynamic from "next/dynamic";
import { formatInTimeZone } from "date-fns-tz";
import { Box, Link, IconFa } from "@cruk/cruk-react-components";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";

import { useSwrGet } from "@fwa/src/hooks/useSwrGet";
import { fwsUrlPageParentMemberships } from "@fwa/src/services/fundraisingPageService";

import { PageMemberOf } from "@fwa/src/components/PageMemberOf";

import {
  ActivityIconWrapper,
  ActivityItem,
  ActivityItemWrapper,
} from "@fwa/src/components/styles";

import {
  ActivityItems,
  TextResponsive,
} from "@fwa/src/components/FundraisingPageActivityBlock/styles";

import {
  type FundraisingPageType,
  type FwsResponseData,
  type TeamMemberType,
} from "@fwa/src/types";

const FundraisingPageActivityDateForm = dynamic(
  () => import("@fwa/src/components/FundraisingPageActivityDateForm"),
);
const FundraisingPageActivityNameForm = dynamic(
  () => import("@fwa/src/components/FundraisingPageActivityNameForm"),
);

const EditableActivityTextComponent = (props: HTMLAttributes<HTMLElement>) => {
  const { children, ...rest } = props;
  return (
    <TextResponsive {...rest} margin="none">
      {children}
    </TextResponsive>
  );
};

type Props = {
  page: FundraisingPageType;
  canEdit: boolean;
  updateAndMutatePage: (
    data: Partial<FundraisingPageType>,
    refresh?: boolean,
  ) => Promise<void | FundraisingPageType>;
};

export const FundraisingPageActivityBlock = ({
  page,
  canEdit,
  updateAndMutatePage,
}: Props) => {
  const url = page
    ? `${fwsUrlPageParentMemberships({
        pageId: page.uniqueId,
        currentPage: 1,
        itemsPerPage: 3,
      })}`
    : null;
  const { data } = useSwrGet<FwsResponseData<TeamMemberType>>(url);
  const memberships = data?.results || [];

  // Example activityDate:  "2020-12-20T11:51:28+00:00" looks like toISOString()
  const activityDateTimeString =
    !!page && page.activityDateTime
      ? formatInTimeZone(
          new Date(page.activityDateTime),
          "Europe/London",
          "EEE d LLL y",
        )
      : "";

  const activityDateTimeEndString =
    page?.activityDateTimeEnd &&
    page?.activityDateTimeEnd !== page?.activityDateTime
      ? ` - ${formatInTimeZone(
          new Date(page.activityDateTimeEnd),
          "Europe/London",
          "EEE d LLL y",
        )}`
      : "";

  const fullDateRangeString = `${activityDateTimeString}${activityDateTimeEndString}`;

  const activityString = !page
    ? ""
    : page.activityName
      ? page.activityName
      : page.event
        ? page.event.eventName
        : "";

  if (canEdit) {
    return (
      <Box
        backgroundColor="backgroundLight"
        paddingTop="none"
        data-component="page-activity-block"
      >
        <ActivityItems>
          <ActivityItemWrapper data-component="page-activity-name-form">
            <FundraisingPageActivityNameForm
              fullWidth
              fieldName="activityName"
              label="Activity description"
              hintText="For example 'Cake sale', 'Head shave', '10k run'"
              text={activityString}
              textComponent={EditableActivityTextComponent}
              placeHolder={
                <div>
                  <Link as="span">Give your activity a description</Link>
                </div>
              }
              validation={{
                type: "textField",
                maxLength: 35,
                regex: {
                  pattern: /^(&#039;|[a-zA-Z0-9\s,'&-]|&amp;)*$/i,
                  message:
                    "Name should only contain letters, hyphens, spaces, apostrophes, commas and ampersands",
                },
              }}
              handleEditData={updateAndMutatePage}
            />
          </ActivityItemWrapper>
          <ActivityItemWrapper data-component="page-activity-date-form">
            <FundraisingPageActivityDateForm
              fieldName="activityDate"
              fullWidth
              allowNullDate
              startDate={page.activityDateTime || null}
              endDate={page.activityDateTimeEnd || null}
              startDateLabel="Activity start date"
              endDateLabel="Activity end date (optional)"
              startDateFieldName="activityDateTime"
              endDateFieldName="activityDateTimeEnd"
              handleEditData={updateAndMutatePage}
              textComponent={EditableActivityTextComponent}
              placeHolder={
                <div>
                  <Link as="span">Give your activity a date</Link>
                </div>
              }
            />
          </ActivityItemWrapper>
          {data ? (
            <PageMemberOf page={page} fallbackData={data} canEdit={canEdit} />
          ) : null}
        </ActivityItems>
      </Box>
    );
  }

  if (activityString.length || page.activityDateTime || !!memberships.length) {
    return (
      <Box
        backgroundColor="backgroundLight"
        paddingTop="none"
        data-component="page-activity-block"
      >
        <ActivityItems>
          {activityString.length ? (
            <ActivityItemWrapper data-component="page-activity-name">
              <ActivityItem>
                <ActivityIconWrapper>
                  <IconFa faIcon={faBullhorn} size="100%" />
                </ActivityIconWrapper>
                <TextResponsive>{activityString}</TextResponsive>
              </ActivityItem>
            </ActivityItemWrapper>
          ) : null}
          {page.activityDateTime ? (
            <ActivityItemWrapper data-component="page-activity-date">
              <ActivityItem>
                <ActivityIconWrapper>
                  <IconFa faIcon={faCalendarDays} size="100%" />
                </ActivityIconWrapper>
                <TextResponsive>{fullDateRangeString}</TextResponsive>
              </ActivityItem>
            </ActivityItemWrapper>
          ) : null}

          {data ? (
            <PageMemberOf page={page} fallbackData={data} canEdit={canEdit} />
          ) : null}
        </ActivityItems>
      </Box>
    );
  }

  return null;
};

export default FundraisingPageActivityBlock;
