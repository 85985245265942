import styled from "styled-components";
import { type ThemeType } from "@cruk/cruk-react-components";

const CARD_WIDTH = "220px";
const IMAGE_WIDTH = "10rem";

export const CarouselOuter = styled.div`
  position: relative;
`;

export const CarouselFade = styled.div<{
  theme: ThemeType;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  pointer-events: none;
  z-index: 11;
  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 0) 90%,
      rgba(255, 255, 255, 1) 100%
    );
  }
`;

export const CarouselScrollArea = styled.ul<{
  $smoothScrolling: boolean;
}>`
  display: flex;
  overflow-x: scroll;
  scroll-snap-align: center;
  scroll-snap-type: x mandatory;
  scroll-behavior: ${({ $smoothScrolling }) =>
    $smoothScrolling ? "smooth" : "auto"};
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  list-style: none;
  margin-bottom: 0px;
`;

export const TextWrapper = styled.div`
  padding: 1em;
  border: solid 1px;
`;

export const ValueWrapper = styled.div<{
  theme: ThemeType;
}>`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

export const BadgeWrapper = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  margin-top: -1em;
  justify-content: space-around;
  z-index: 10;
`;

export const CarouselCard = styled.li<{
  theme: ThemeType;
  $isFirst: boolean;
  $isLast: boolean;
}>`
  position: relative;
  scroll-snap-align: center;
  min-width: 60%;
  white-space: normal;
  margin: 0 ${({ theme }) => `0 ${theme.spacing.s} ${theme.spacing.s}`};
  margin-left: ${({ $isFirst, theme }) =>
    $isFirst ? theme.spacing.l : theme.spacing.s};
  &:before {
    display: ${({ $isFirst }) => ($isFirst ? "none" : "block")};
    z-index: 0;
    content: "";
    position: absolute;
    top: calc((${IMAGE_WIDTH} / 2) + 2px);
    left: -${({ theme }) => theme.spacing.s};
    width: calc(100% - ${({ theme }) => theme.spacing.s} * 2);
    height: 4px;
    background-color: #000;
  }
  &:after {
    display: ${({ $isLast }) => ($isLast ? "none" : "block")};
    z-index: 0;
    content: "";
    position: absolute;
    top: calc((${IMAGE_WIDTH} / 2) + 2px);
    right: -${({ theme }) => theme.spacing.s};
    width: calc(100% - ${({ theme }) => theme.spacing.s} * 2);
    height: 4px;
    background-color: #000;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    min-width: ${CARD_WIDTH};
  }
`;

export const RoundedImageWrapper = styled.div<{
  theme: ThemeType;
  $isCurrent: boolean;
}>`
  position: relative;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
  max-width: ${IMAGE_WIDTH};
  max-height: ${IMAGE_WIDTH};
  border: 2px dashed ${({ theme }) => theme.tokenColors.grey_600};
  border-style: ${({ $isCurrent }) => ($isCurrent ? "none" : "dashed")};
  background-color: white;
  z-index: 10;
  && img {
    object-fit: cover;
    width: ${IMAGE_WIDTH};
    height: ${IMAGE_WIDTH};
    filter: ${({ $isCurrent }) =>
      `${$isCurrent ? "grayscale(0%)" : "grayscale(100%)"} ${$isCurrent ? "opacity(100%)" : "opacity(30%)"}`};
  }
`;
