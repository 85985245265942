import { type Dispatch, type SetStateAction } from "react";
import { Box, Collapse, Link } from "@cruk/cruk-react-components";
import { RegexYouTubeUrl } from "@fwa/src/utils/formUtils";
import { EditableTextField } from "@fwa/src/components/EditableTextField";
import { YouTubeInstructionList } from "@fwa/src/components/EditableVideoUrlCustom/styles";

type Props = {
  textState: string;
  setTextState: Dispatch<SetStateAction<string>>;
  validationMessage: string;
  setValidationMessage: Dispatch<SetStateAction<string>>;
};

export const EditableVideoUrlCustom = ({
  textState,
  setTextState,
  validationMessage,
  setValidationMessage,
}: Props) => (
  <Box marginTop="s">
    <EditableTextField
      label="Enter your YouTube video share link"
      hintText="Click 'Share' on your chosen YouTube video and copy the link"
      text={textState}
      setCurrentValue={(value) => {
        setTextState(value);
      }}
      validation={
        textState.length
          ? {
              regex: RegexYouTubeUrl,
              maxLength: 400,
            }
          : { maxLength: 400 }
      }
      validationMessage={validationMessage}
      setValidationMessage={setValidationMessage}
    />
    <Collapse
      id="How to upload a video to youtube"
      headerTitleText="How to upload a video to Youtube"
    >
      <Box marginBottom="m">
        <YouTubeInstructionList>
          <li>Open YouTube</li>
          <li>Tap Create</li>
          <li>Select the file you’d like to upload and tap NEXT</li>
        </YouTubeInstructionList>
        <Box marginTop="xxs">
          <Link
            href="https://www.youtube.com/watch?v=NDNt44uAg-Y&t=2s"
            target="_blank"
            rel="nofollow noopener"
            data-cta-type="link-watch-instruction-video"
          >
            Watch instruction video
          </Link>
        </Box>
      </Box>
    </Collapse>
  </Box>
);

export default EditableVideoUrlCustom;
