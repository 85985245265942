import styled from "styled-components";
import { Avatar, type ThemeType } from "@cruk/cruk-react-components";

export const ImageWrapper = styled.div`
  text-align: right;
  margin-top: -20px;
`;

export const TextWrapper = styled.div<{ theme: ThemeType }>`
  padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.s} 0
    ${({ theme }) => theme.spacing.s};
`;

export const RestrictionImage = styled.img`
  height: 120px;
  width: auto;
  vertical-align: bottom;
  text-align: right;
`;

export const AvatarSpacing = styled(Avatar)<{ theme: ThemeType }>`
  margin-right: ${({ theme }) => theme.spacing.s};
`;
