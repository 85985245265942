import { type Dispatch, type SetStateAction } from "react";
import { Box, Text } from "@cruk/cruk-react-components";
import { Row } from "@fwa/src/components/styles";
import {
  VideButtonWrapper,
  VideoButton,
} from "@fwa/src/components/EditableVideoUrlDefault/styles";

type Props = {
  textState: string;
  setTextState: Dispatch<SetStateAction<string>>;
};

const defaultVideosUuid = [
  { id: "Vd3_oztfimk", title: "together we are beating cancer" },
  { id: "i8Ed2QRWgqA", title: "Thank You" },
];

export const EditableVideoUrlDefault = ({ textState, setTextState }: Props) => {
  const handleDefaultChange = (id: string) => {
    setTextState(`https://youtu.be/${id}`);
  };

  return (
    <Box marginTop="s">
      <Text textWeight={700} marginBottom="xxs">
        Select from the videos below
      </Text>
      <Text>
        {`Select one of our videos and click 'Save' to set it on your
        page`}
      </Text>
      <Row>
        {defaultVideosUuid.map(({ id, title }) => (
          <VideButtonWrapper key={id}>
            <VideoButton
              aria-label={`Select ${title}`}
              onClick={() => handleDefaultChange(id)}
              $isActive={`https://youtu.be/${id}` === textState}
              data-cta-type="video-select"
            >
              <img src={`https://img.youtube.com/vi/${id}/0.jpg`} alt="" />
            </VideoButton>
          </VideButtonWrapper>
        ))}
      </Row>
    </Box>
  );
};

export default EditableVideoUrlDefault;
