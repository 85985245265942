import { useEffect } from "react";
import { type NextPageContext } from "next";
import Head from "next/head";
import { getCache } from "@fwa/src/store/SwrCache";

import { pageFetcher } from "@fwa/src/services/apiClient";
import { fwsUrlFundraisingPageSlug } from "@fwa/src/services/fundraisingPageService";
import { fwsUrlFeedItem } from "@fwa/src/services/feedItemService";
import { queryAsString } from "@fwa/src/utils/urlUtils";
import { isBrowser } from "@fwa/src/utils/browserUtils";
import { getShareContentFromEntity } from "@fwa/src/utils/shareUtils";
import { useBrandContext } from "@fwa/src/contexts/BrandContext";

import { FundraisingPage } from "@fwa/src/components/FundraisingPage";
import { ErrorPageWrapper } from "@fwa/src/components/ErrorPageWrapper";

import {
  type FundraisingPageType,
  type ResponseError,
  type FeedItemType,
  type BrandNameType,
} from "@fwa/src/types";

type PageProps = {
  pageData?: FundraisingPageType;
  error?: ResponseError;
  sharedFeedItemData?: FeedItemType;
  themeName?: BrandNameType;
};

const Page = ({
  pageData,
  error,
  themeName,
  sharedFeedItemData,
}: PageProps) => {
  const [brandName, setBrandName] = useBrandContext();

  useEffect(() => {
    if (!!themeName && themeName !== brandName) {
      setBrandName(themeName);
    }
  }, [themeName, brandName, setBrandName]);

  const shareData = sharedFeedItemData || pageData;

  const { title, description, image } = shareData
    ? getShareContentFromEntity(shareData)
    : { title: "", description: "", image: "" };

  return (
    <ErrorPageWrapper error={error}>
      {pageData && (
        <>
          <Head>
            <title>{title}</title>
            <meta
              name="viewport"
              content="initial-scale=1.0, width=device-width"
            />
            <meta name="robots" content="noindex" />
            {/* sharing meta */}
            <meta property="og:title" content={title} />
            {description?.length && (
              <>
                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
              </>
            )}
            <meta
              property="og:url"
              content={`${pageData?.url}${
                sharedFeedItemData ? `?feed=${sharedFeedItemData.uniqueId}` : ""
              }`}
            />
            <meta property="og:type" content="website" />
            {image && (
              <>
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="og:image" content={image} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
              </>
            )}
            {/* end sharing meta */}
          </Head>
          <FundraisingPage
            pageData={pageData}
            sharedFeedItemData={sharedFeedItemData}
          />
        </>
      )}
    </ErrorPageWrapper>
  );
};

Page.getInitialProps = async ({
  query,
  res,
}: NextPageContext): Promise<PageProps> => {
  if (res) {
    res.setHeader("Cache-Control", "max-age=300, stale-while-revalidate=3600");
  }

  const { slug, feed } = query;
  const slugString = queryAsString(slug);
  const feedString = queryAsString(feed);
  const getThemeName = (page: FundraisingPageType) =>
    page?.fundraisingPageType?.brand?.name;

  // ---Page data---
  const url = fwsUrlFundraisingPageSlug({ slug: slugString || "" });
  if (isBrowser) {
    const cache = getCache();
    const cachedData = cache.get(url)?.data as FundraisingPageType;
    if (cachedData)
      return {
        pageData: cachedData,
        error: undefined,
        themeName: getThemeName(cachedData),
      };
  }
  const { pageData, error } = await pageFetcher<FundraisingPageType>(url);

  // ---Shared feed item data---
  // Let SWR handle errors async component uses modals/react portal and needs DOM anyway
  // There just wont be any initial data,
  const feedItemUrl = feedString.length
    ? fwsUrlFeedItem({ feedItemId: feedString })
    : null;
  const { pageData: sharedFeedItemData } =
    await pageFetcher<FeedItemType>(feedItemUrl);

  return {
    pageData,
    error,
    sharedFeedItemData,
    themeName: getThemeName(pageData!),
  };
};

export default Page;
