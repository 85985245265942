import { type ThemeType } from "@cruk/cruk-react-components";
import styled from "styled-components";

export const PartyPopper = styled.img<{ theme: ThemeType }>`
  width: 90px;
  display: block;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.spacing.s};
`;

export const MessageWrapper = styled.div<{ theme: ThemeType }>`
  margin-top: ${({ theme }) => theme.spacing.xl};
`;
