import styled from "styled-components";
import { Box, type ThemeType } from "@cruk/cruk-react-components";

const LEFT_COLUMN_WIDTH = "376px";

export const ActionButtonWrapper = styled(Box)<{ theme: ThemeType }>`
  width: 100%;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.backgroundLight} !important;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  border-top: solid 1px ${({ theme }) => theme.tokenColors.grey_300};
  box-shadow: ${({ theme }) => theme.shadows.l};
`;

export const ActionButtonFlex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-direction: row;
`;

export const TotaliserButtonsFlex = styled.div<{ theme: ThemeType }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap-reverse;

  > * {
    margin: ${({ theme }) => `0 ${theme.spacing.xs} ${theme.spacing.xs}`};
  }
`;

export const DesktopRight = styled.div<{ theme: ThemeType }>`
  display: block;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    width: ${({ theme }) =>
      `calc(100% - ${LEFT_COLUMN_WIDTH} - ${theme.spacing.m})`};
  }
`;

export const DesktopLeft = styled.div<{ theme: ThemeType }>`
  display: block;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    width: ${LEFT_COLUMN_WIDTH};
  }
  margin-right: ${({ theme }) => theme.spacing.m};
`;

export const ColumnWrapper = styled.div<{ theme: ThemeType }>`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: row-reverse;
  }
`;
