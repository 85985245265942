import { FWS_BASE_URL, FWA_BASE_URL } from "@fwa/src/services/apiClient";
import { isBrowser } from "@fwa/src/utils/browserUtils";
import { buildQuery } from "@fwa/src/utils/urlUtils";

const FITBIT_ENV_CLIENT_ID = process.env.NEXT_PUBLIC_FITBIT_CLIENT_ID || "";

export const fwsUrlNewFitbitUser = ({
  fundraiserId,
}: {
  fundraiserId: string;
}): string => `${FWS_BASE_URL}/fundraisers/${fundraiserId}/fitbitusers`;

export const fwsUrlNewFitbit = ({ pageId }: { pageId: string }): string =>
  `${FWS_BASE_URL}/fundraisingpages/${pageId}/fitbits`;

export const fwsUrlFitbit = ({ fitbitId }: { fitbitId: string }): string =>
  `${FWS_BASE_URL}/fitbits/${fitbitId}`;

export const fitbitUrlOauth = (pageState: string) =>
  `https://www.fitbit.com/oauth2/authorize${buildQuery({
    client_id: FITBIT_ENV_CLIENT_ID,
    response_type: "code",
    redirect_uri: `${
      isBrowser ? window.origin : FWA_BASE_URL
    }/user/fitbit/return`,
    scope: "activity",
    state: pageState,
  })}`;
