import { useState } from "react";
import {
  Heading,
  Box,
  ErrorText,
  ProgressBar,
  Text,
  crukTheme,
  IconFa,
} from "@cruk/cruk-react-components";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";
import { fetcher } from "@fwa/src/services/apiClient";
import { fwsUrlStrava } from "@fwa/src/services/stravaService";
import { numberWithCommas } from "@fwa/src/utils/formatUtils";
import { Editable } from "@fwa/src/components/Editable";
import { EditableTextField } from "@fwa/src/components/EditableTextField";
import { OnlyYou } from "@fwa/src/components/OnlyYou";

import {
  MainWrapper,
  TargetWrapper,
  TextWrapper,
  StyledLogo,
  InputWrapper,
  RowAligned,
  EditIconWrapper,
  FloatingIcon,
} from "@fwa/src/components/StravaTracker/styles";
import { DashedBorder } from "@fwa/src/components/styles";

import { type StravaType, type FundraisingPageType } from "@fwa/src/types";

const stravaLogo = "/assets/images/logos/strava-rounded-128.png";

const METERS_TO_MILES = 1609.344;

const targetMetersToUnit = (target: number, isMetric: boolean) => {
  const divisor = isMetric ? 1000 : METERS_TO_MILES;
  return !target ? 0 : Math.round((target / divisor) * 10) / 10;
};

const distanceMetersToUnit = (totalDistanceRaw: number, isMetric: boolean) => {
  const distance = totalDistanceRaw / (isMetric ? 1000 : METERS_TO_MILES);
  if (distance < 100) return Math.floor(distance * 10) / 10;
  return Math.floor(distance);
};

const calculatePercent = (target: number, distance: number) => {
  const percent = (distance / target) * 100;
  if (percent === Number.POSITIVE_INFINITY || Number.isNaN(percent)) {
    return 0;
  }
  return Math.floor(percent < 1 ? Math.ceil(percent) : Math.floor(percent));
};

type NoEditProps = {
  canEdit?: boolean;
  totalDistance: number;
  targetDistance: number | null;
  unit: string;
  useMetric: boolean;
};

const NonEditingView = ({
  canEdit,
  totalDistance,
  targetDistance,
  unit,
  useMetric,
}: NoEditProps) => (
  <Box margin="none" paddingRight="xs">
    <RowAligned>
      <StyledLogo src={stravaLogo} alt="" />
      <Heading h2 marginVertical="none" textSize="xl">
        Strava activity tracker
      </Heading>
    </RowAligned>
    <MainWrapper>
      <ProgressBar
        circleSize="110px"
        barColor={crukTheme.tokenColors.cyan_900}
        circleContents={
          <Box>
            <Text
              textAlign="center"
              textSize="l"
              marginBottom="none"
              marginHorizontal="xs"
            >
              {numberWithCommas(distanceMetersToUnit(totalDistance, useMetric))}
            </Text>
            <Text textAlign="center">{unit}</Text>
          </Box>
        }
        percentage={
          targetDistance
            ? calculatePercent(
                targetMetersToUnit(targetDistance, useMetric),
                distanceMetersToUnit(totalDistance, useMetric),
              )
            : 0
        }
        isCircular
      />
      {targetDistance ? (
        <TextWrapper>
          <Text
            textAlign="center"
            textSize="xl"
            marginLeft="xs"
            marginBottom="none"
          >
            {`${calculatePercent(
              targetMetersToUnit(targetDistance, useMetric),
              distanceMetersToUnit(totalDistance, useMetric),
            )}%`}
          </Text>
          <Text
            marginLeft="xs"
            marginRight="m"
            marginBottom="none"
          >{` of ${numberWithCommas(
            targetMetersToUnit(targetDistance, useMetric),
          )} ${unit} target`}</Text>
          {canEdit && (
            <FloatingIcon>
              <IconFa faIcon={faPenToSquare} />
            </FloatingIcon>
          )}
        </TextWrapper>
      ) : (
        <Text marginHorizontal="xs">
          {`Distance travelled ${numberWithCommas(
            distanceMetersToUnit(totalDistance, useMetric),
          )} ${unit}`}
        </Text>
      )}
    </MainWrapper>
  </Box>
);

type Props = {
  canEdit: boolean;
  strava: StravaType;
  mutatePage: (
    data: Partial<FundraisingPageType>,
  ) => Promise<undefined | void | FundraisingPageType>;
};

export const StravaTracker = ({ strava, mutatePage, canEdit }: Props) => {
  const { trackError } = useTrackingContext();
  const { useMetric, totalDistance, targetDistance } = strava;
  const unit = useMetric ? "km" : "miles";
  // TODO refactor to reduce decimal math.
  const [targetState, setTargetState] = useState<number>(targetDistance || 0);

  const [validationMessage, setValidationMessage] = useState<string>("");
  const [submissionErrorMessage, setSubmissionErrorMessage] =
    useState<string>("");

  const handleSubmit = async (): Promise<undefined> => {
    setSubmissionErrorMessage("");

    // update strava object attached to page
    const updatedStrava: StravaType | undefined = await fetcher(
      fwsUrlStrava({ stravaId: strava.uniqueId }),
      {
        method: "PATCH",
        body: JSON.stringify({ targetDistance: targetState }),
      },
    )
      .then((res) => res as StravaType)
      .catch((err) => {
        trackError(err as Error, {
          component: "StravaTracker",
          function: "handleSubmit",
        });
        setSubmissionErrorMessage("Unable to update target");

        return undefined;
      });

    if (!updatedStrava) {
      const err = new Error("no updated strava returned");
      trackError(err, {
        component: "StravaTracker",
        function: "handleSubmit",
      });
      setSubmissionErrorMessage("Unable to update target");

      return undefined;
    }

    // mutate page with updated strava info

    const partialData = { strava: updatedStrava };
    mutatePage(partialData).catch((err: Error) => {
      trackError(err, {
        component: "StravaTracker",
        function: "mutatePage",
      });
    });

    return undefined;
  };

  const handleTargetChange = (value: number | string) => {
    const numberValuse = value as number;
    const targetValue = Math.round(
      useMetric ? numberValuse * 1000 : numberValuse * METERS_TO_MILES,
    );
    setTargetState(Math.round(+targetValue * 10) / 10);
  };

  if (!canEdit)
    return (
      <NonEditingView
        canEdit={canEdit}
        totalDistance={totalDistance}
        targetDistance={targetDistance}
        unit={unit}
        useMetric={useMetric}
      />
    );

  return (
    <Editable
      isValid={!validationMessage.length}
      editNode={
        <Box paddingRight="xs">
          <RowAligned>
            <StyledLogo src={stravaLogo} alt="" />
            <Heading h2 marginRight="xs" marginVertical="none" textSize="xl">
              Strava activity tracker{" "}
            </Heading>
          </RowAligned>
          <MainWrapper>
            <ProgressBar
              circleSize="110px"
              barColor={crukTheme.tokenColors.cyan_900}
              circleContents={
                <Box>
                  <Text textAlign="center" textSize="l" marginBottom="none">
                    {numberWithCommas(
                      distanceMetersToUnit(totalDistance, useMetric),
                    )}
                  </Text>
                  <Text textAlign="center">{unit}</Text>
                </Box>
              }
              percentage={calculatePercent(
                targetState,
                distanceMetersToUnit(totalDistance, useMetric),
              )}
              isCircular
            />
            <Box>
              <Text textSize="xl" marginHorizontal="xs" marginBottom="none">
                {calculatePercent(
                  targetState,
                  distanceMetersToUnit(totalDistance, useMetric),
                )}
                %
              </Text>
              <Box>
                <TargetWrapper>
                  <Text as="span" marginLeft="xs">
                    of
                  </Text>
                  <InputWrapper>
                    <EditableTextField
                      label=""
                      aria-label="Set a target distance"
                      validationMessage={validationMessage}
                      setValidationMessage={setValidationMessage}
                      setCurrentValue={handleTargetChange}
                      text={String(
                        targetDistance
                          ? targetMetersToUnit(targetDistance, useMetric)
                          : 0,
                      )}
                      validation={{
                        type: "number",
                        minValue: 0,
                        maxValue: 1000000,
                      }}
                    />
                    {submissionErrorMessage && (
                      <ErrorText>{submissionErrorMessage}</ErrorText>
                    )}
                  </InputWrapper>
                  <Text
                    as="span"
                    marginHorizontal="xs"
                  >{` ${unit} target`}</Text>
                </TargetWrapper>
              </Box>
            </Box>
          </MainWrapper>
        </Box>
      }
      viewNode={
        <>
          <NonEditingView
            canEdit={canEdit}
            totalDistance={totalDistance}
            targetDistance={targetDistance}
            unit={unit}
            useMetric={useMetric}
          />

          {!targetState && (
            <Box marginTop="s" marginBottom="none">
              <DashedBorder>
                <OnlyYou />

                <Text marginBottom="none">Add a challenge target</Text>
                <EditIconWrapper>
                  <IconFa faIcon={faPenToSquare} />
                </EditIconWrapper>
              </DashedBorder>
            </Box>
          )}
        </>
      }
      handleSubmit={handleSubmit}
      tooltip="Edit strava target distance"
      editButtonColor="rgba(255,255,255,0)"
    />
  );
};

export default StravaTracker;
