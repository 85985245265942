import { type ThemeType } from "@cruk/cruk-react-components";
import styled from "styled-components";

export const VideoButtonWrapper = styled.div<{ theme: ThemeType }>`
  cursor: pointer;
  text-align: center;
  position: relative;
  vertical-align: top;
  padding: ${({ theme }) => theme.spacing.xxs}
    ${({ theme }) => theme.spacing.xxs} ${({ theme }) => theme.spacing.xxs} 0;
  display: inline-block;
  width: 50%;
  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    width: 33%;
  }
`;

export const VideoButtonOuter = styled.div<{ theme: ThemeType }>`
  position: relative;
  padding-bottom: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundMid};
  box-shadow: ${({ theme }) => theme.shadows.s};
`;

export const VideoButtonInner = styled.div<{ theme: ThemeType }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  color: ${({ theme }) => theme.tokenColors.grey_600};
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 0.5;
  }
`;
