import { Box, IconFa } from "@cruk/cruk-react-components";
import { faVideoSlash } from "@fortawesome/free-solid-svg-icons";
import {
  VideoPlaceholderWrapper,
  VideoPlaceholderOuter,
  VideoPlaceholderInner,
} from "@fwa/src/components/FundraisingPageVideoPlaceholder/styles";
import { RowSpaceAround } from "@fwa/src/components/styles";

export const FundraisingPageVideoPlaceholder = () => (
  <VideoPlaceholderWrapper>
    <VideoPlaceholderOuter>
      <VideoPlaceholderInner>
        <Box>
          <RowSpaceAround>
            <IconFa faIcon={faVideoSlash} size="20%" />
          </RowSpaceAround>
        </Box>
      </VideoPlaceholderInner>
    </VideoPlaceholderOuter>
  </VideoPlaceholderWrapper>
);

export default FundraisingPageVideoPlaceholder;
