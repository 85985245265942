import dynamic from "next/dynamic";

import { Box, Totaliser } from "@cruk/cruk-react-components";

import {
  calculatePercentRounded,
  formatMoneyWithCommas,
} from "@fwa/src/utils/formatUtils";

import { type FundraisingPageType } from "@fwa/src/types";

const EditableTargetForm = dynamic(
  () => import("@fwa/src/components/EditableTargetForm"),
);

type Props = {
  page: FundraisingPageType;
  canEdit: boolean;
  updateAndMutatePage: (
    data: Partial<FundraisingPageType>,
  ) => Promise<void | undefined | FundraisingPageType>;
};

export const FundraisingPageTotaliser = ({
  page,
  canEdit,
  updateAndMutatePage,
}: Props) => {
  const percentageOfTarget = page
    ? calculatePercentRounded(page.donationsTotalAmount, page.target)
    : 0;
  const target = formatMoneyWithCommas(page?.target ? page.target : 0);

  return canEdit ? (
    <Box id="target" marginBottom="xxs">
      <EditableTargetForm
        page={page}
        fieldName="target"
        totaliserProps={{
          isCompact: false,
          summaryMessage: `${percentageOfTarget}% of £${target} target`,
        }}
        handleEditData={updateAndMutatePage}
      />
    </Box>
  ) : (
    <Totaliser
      isCompact={false}
      target={page.target}
      total={page.donationsTotalAmount}
      giftAid={page.donationsGiftAidTotalAmount}
    />
  );
};

export default FundraisingPageTotaliser;
