import NextLink from "next/link";
import { useSwrGet } from "@fwa/src/hooks/useSwrGet";
import { Heading, Text, Box, Link } from "@cruk/cruk-react-components";

import { fwsUrlFundraiserGetFirstOpenFundraisingPage } from "@fwa/src/services/fundraiserService";

import { type FundraisingPageType, type FwsResponseData } from "@fwa/src/types";

type Props = {
  userName: string;
  fundraiserId: string;
};

export const FundraisingPageClosedInfo = ({
  userName,
  fundraiserId,
}: Props) => {
  const url = fundraiserId
    ? `${fwsUrlFundraiserGetFirstOpenFundraisingPage({
        fundraiserId,
      })}`
    : null;
  const { data } = useSwrGet<FwsResponseData<FundraisingPageType>>(url);
  const firstPage = data?.results[0];

  return (
    <Box backgroundColor="backgroundLight">
      <Heading h2>This Giving Page is now closed</Heading>
      <Text>
        Thank you for raising money for life-saving work. All the money raised
        is being used to help beat cancer
      </Text>
      <Text>Still want to donate?</Text>
      {firstPage && (
        <>
          <Box marginBottom="xs">
            <NextLink
              href={firstPage.url.replace(/https?:\/\/[^/]*/, "")}
              data-cta-type="link-other-page"
            >
              <Link appearance="primary" as="span">
                {`View ${userName}'s other Giving Page`}
              </Link>
            </NextLink>
          </Box>
          <Text>or</Text>
        </>
      )}
      <Box>
        <Link
          appearance="primary"
          href="https://www.cancerresearchuk.org/get-involved/donate"
        >
          Donate directly to Cancer Research UK
        </Link>
      </Box>
    </Box>
  );
};

export default FundraisingPageClosedInfo;
