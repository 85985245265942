import { type ThemeType, Text } from "@cruk/cruk-react-components";
import styled from "styled-components";

export const ActivityItems = styled.div<{ theme: ThemeType }>`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: row;
    justify-content: center;
  }
`;

export const TextResponsive = styled(Text)<{ theme: ThemeType }>`
  text-align: left;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    text-align: center;
  }
`;

export default ActivityItems;
