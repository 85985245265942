import styled from "styled-components";
import { Box, type ThemeType } from "@cruk/cruk-react-components";

const VIDEO_BUTTON_WIDTH = "192px";

export const VideButtonWrapper = styled(Box)<{
  theme: ThemeType;
}>`
  margin-right: ${({ theme }) => theme.spacing.s};
  margin-bottom: 0;
  &:last-child {
    margin-right: 0;
  }
`;

export const VideoButton = styled.button<{
  $isActive: boolean;
  theme: ThemeType;
}>`
  background-color: rgba(255, 255, 255, 0);
  height: 100%;
  max-width: ${VIDEO_BUTTON_WIDTH};
  box-sizing: content-box;
  border: solid 4px
    ${({ theme, $isActive }) =>
      $isActive ? theme.colors.secondary : theme.colors.inputBorder};
  padding: 0;
  img {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
`;
