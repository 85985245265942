import { type ThemeType } from "@cruk/cruk-react-components";
import styled from "styled-components";
import dynamic from "next/dynamic";

const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });
const VIDEO_PREVIEW_WIDTH = "18rem";

export const VideoWrapper = styled.div<{
  theme: ThemeType;
  $showPlaceholder: boolean;
}>`
  position: relative;
  max-width: ${VIDEO_PREVIEW_WIDTH};
  margin-bottom: ${({ theme, $showPlaceholder }) =>
    $showPlaceholder ? theme.spacing.s : 0};
`;

export const ResponsiveReactPlayer = styled(ReactPlayer)`
  aspect-ratio: 16 / 9;
`;
