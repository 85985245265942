import React from "react";
import { Text, Heading, Box } from "@cruk/cruk-react-components";

import { useBrandContext } from "@fwa/src/contexts/BrandContext";
import { imagePathFromImageType } from "@fwa/src/services/imageService";

import { Row, ColumnSpaceAround } from "@fwa/src/components/styles";

import { type RestrictionType } from "@fwa/src/types";

import {
  RestrictionImage,
  TextWrapper,
  ImageWrapper,
  AvatarSpacing,
} from "./styles";

const restrictionFrameSu2c = "/assets/images/img/arrows.jpg";

const noneRestrictedImg = "/assets/images/img/none-restricted-all-cancers.png";

type Props = {
  restriction: RestrictionType | null;
};

export const RestrictionSection = ({ restriction }: Props) => {
  const noneRestrictedPage: RestrictionType = {
    restrictionCode: "undefined",
    description:
      "Our work has helped double cancer survival in the UK in the last 50 years. Despite this incredible progress, there’s more to be done. Nearly 1 in 2 of us will get cancer in our lifetime. Help us carry out world-class research into the prevention, detection and treatment of more than 200 types of cancer.",
    hidden: false,
    title: "All Cancer Types",
    image: {
      uniqueId: "",
      url: noneRestrictedImg,
      width: 120,
      height: 120,
    },
  };
  const [brandName] = useBrandContext();
  const isSu2c = brandName === "su2c";
  const isBowelBabe = brandName === "bowelbabe";
  const restrictionObject = restriction ?? noneRestrictedPage;
  const restrictionImageUrl = restrictionObject.image
    ? imagePathFromImageType(restrictionObject.image)
    : "";
  const { title, description, image } = restrictionObject;

  if (!title || !description || !image) return null;

  // bowelbabe has no image at the bottom
  const restrictionImageSrc = isBowelBabe
    ? ""
    : isSu2c
      ? restrictionFrameSu2c
      : null;

  return (
    <Box
      padding="none"
      backgroundColor="backgroundLight"
      data-component="page-restrictions"
    >
      <TextWrapper>
        <Box>
          <Row>
            <AvatarSpacing size="l" url={restrictionImageUrl} />
            <ColumnSpaceAround>
              <Text marginBottom="xxs" textColor="textDark">
                CAUSE
              </Text>
              <Heading marginTop="none" marginBottom="none" h2 textSize="xl">
                {restrictionObject.title}
              </Heading>
            </ColumnSpaceAround>
          </Row>
        </Box>
        <Text paddingBottom={isSu2c ? "l" : "s"}>{description}</Text>
      </TextWrapper>
      {restrictionImageSrc ? (
        <ImageWrapper>
          <RestrictionImage alt="" loading="lazy" src={restrictionImageSrc} />
        </ImageWrapper>
      ) : null}
    </Box>
  );
};

export default RestrictionSection;
