import { Box, IconFa, Text } from "@cruk/cruk-react-components";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { RowSpaceAround } from "@fwa/src/components/styles";
import {
  VideoButtonWrapper,
  VideoButtonOuter,
  VideoButtonInner,
} from "@fwa/src/components/AddYouTubeVideoButton/styles";

export const AddYouTubeVideoButton = () => (
  <VideoButtonWrapper>
    <VideoButtonOuter>
      <VideoButtonInner>
        <Box>
          <RowSpaceAround>
            <IconFa faIcon={faPlay} size="20%" />
          </RowSpaceAround>
          <Text marginTop="xxs" textAlign="center">
            Add YouTube video
          </Text>
        </Box>
      </VideoButtonInner>
    </VideoButtonOuter>
  </VideoButtonWrapper>
);

export default AddYouTubeVideoButton;
