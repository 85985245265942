import { useEffect, useRef, useState } from "react";
import { Box, Heading } from "@cruk/cruk-react-components";
import { useInView } from "react-intersection-observer";
import { type PageType } from "@fwa/src/types";
import { EditableVideoUrlForm } from "@fwa/src/components/EditableVideoUrlForm";
import { useOptimizelyContext } from "@fwa/src/contexts/OptimizelyContext";
import { ResponsiveReactPlayer } from "@fwa/src/components/FundraisingPageVideo/styles";

type Props = {
  canEdit: boolean;
  page: PageType;
  handleEditData: (
    data: Partial<PageType>,
    refresh?: boolean,
  ) => Promise<void | PageType | undefined>;
};

export const FundraisingPageVideo = ({
  canEdit,
  page,
  handleEditData,
}: Props) => {
  const { setOptimizelyUserAttributes } = useOptimizelyContext();

  const videoRef = useRef<HTMLDivElement>(null);
  const [hasPlayed, setHasPlayed] = useState(false);

  const { ref: innerRef, inView } = useInView({
    triggerOnce: true,
  });

  const handlePlay = () => {
    setOptimizelyUserAttributes({ video_played: 1 });
    setHasPlayed(true);
  };

  useEffect(() => {
    if (inView) {
      const actualHasPlayed = hasPlayed ? 1 : 0;
      setOptimizelyUserAttributes({ video_played: actualHasPlayed });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const handleEditDataAndScroll = (
    data: Partial<PageType>,
    refresh?: boolean,
  ) => {
    if (videoRef.current) {
      // works without options param
      videoRef.current.scrollIntoView({
        block: "start",
      });
    }
    return handleEditData(data, refresh);
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {canEdit || page.videoUrl ? (
        <Box
          marginBottom="xxs"
          paddingTop="l"
          ref={videoRef}
          data-component="video"
        >
          <Heading h2 textSize="xl" marginBottom="xs">
            My Video
          </Heading>
          {canEdit ? (
            <EditableVideoUrlForm
              handleEditData={handleEditDataAndScroll}
              page={page}
            />
          ) : page?.videoUrl ? (
            <div ref={innerRef}>
              <ResponsiveReactPlayer
                url={page.videoUrl || ""}
                height="auto"
                width="100%"
                onPlay={() => handlePlay()}
                config={{
                  youtube: {
                    playerVars: {
                      origin: "https://www.youtube.com",
                      // widget_referrer: FWA_BASE_URL,  can track our views in YT studio
                    },
                  },
                }}
              />
            </div>
          ) : null}
        </Box>
      ) : null}
    </>
  );
};

export default FundraisingPageVideo;
