import styled from "styled-components";
import { type ThemeType } from "@cruk/cruk-react-components";

export const DisabledTextBorder = styled.div<{ theme: ThemeType }>`
  background-color: ${({ theme }) => theme.tokenColors.grey_200};
  margin-bottom: ${({ theme }) => theme.spacing.xxs};
  > * {
    min-height: ${({ theme }) => theme.spacing.s};
    padding: ${({ theme }) => theme.spacing.xs};
  }
`;
