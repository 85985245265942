import { FWS_BASE_URL } from "@fwa/src/services/apiClient";

export const activityList = [
  "Ride",
  "Run",
  "Walk",
  "Swim",
  "AlpineSki",
  "BackcountrySki",
  "Canoeing",
  "Crossfit",
  "EBikeRide",
  "Elliptical",
  "Golf",
  "Handcycle",
  "Hike",
  "IceSkate",
  "InlineSkate",
  "Kayaking",
  "Kitesurf",
  "NordicSki",
  "RockClimbing",
  "RollerSki",
  "Rowing",
  "Sail",
  "Skateboard",
  "Snowboard",
  "Snowshoe",
  "Soccer",
  "StairStepper",
  "StandUpPaddling",
  "Surfing",
  "Velomobile",
  "VirtualRide",
  "VirtualRun",
  "WeightTraining",
  "Wheelchair",
  "Windsurf",
  "Workout",
  "Yoga",
];

export const fwsUrlFundriasingPageNewStrava = ({
  pageId,
}: {
  pageId: string;
}): string => `${FWS_BASE_URL}/fundraisingpages/${pageId}/stravas`;

export const fwsUrlStrava = ({ stravaId }: { stravaId: string }): string =>
  `${FWS_BASE_URL}/stravas/${stravaId}`;
