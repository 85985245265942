import { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  IconFa,
  Text,
  type ThemeType,
} from "@cruk/cruk-react-components";

import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "styled-components";
import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";
import { type PageType } from "@fwa/src/types";
import { useDebounce } from "@fwa/src/hooks/useDebounce";
import { Editable } from "@fwa/src/components/Editable";
import { AddYouTubeVideoButton } from "@fwa/src/components/AddYouTubeVideoButton";
import { FundraisingPageVideoPlaceholder } from "@fwa/src/components/FundraisingPageVideoPlaceholder";
import { EditableVideoUrlCustom } from "@fwa/src/components/EditableVideoUrlCustom";
import { EditableVideoUrlDefault } from "@fwa/src/components/EditableVideoUrlDefault";

import {
  ColumnSpaceAround,
  RowSpaceAround,
  TabButton,
  TabRow,
} from "@fwa/src/components/styles";

import {
  VideoWrapper,
  ResponsiveReactPlayer,
} from "@fwa/src/components/EditableVideoUrlForm/styles";

type Props = {
  handleEditData: (
    data: Partial<PageType>,
    refresh?: boolean,
  ) => Promise<void | PageType | undefined>;
  page: PageType;
};

export const EditableVideoUrlForm = ({ handleEditData, page }: Props) => {
  const theme = useTheme();
  const typedTheme = theme as ThemeType;
  const { trackError } = useTrackingContext();
  const [textState, setTextState] = useState<string>(page.videoUrl || "");
  const [urlState, setUrlState] = useState<string>(page.videoUrl || "");
  const [validationMessage, setValidationMessage] = useState<string>("");
  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<"default" | "custom">("default");
  const debounceValue = useDebounce<string>(textState, 750);
  const fieldName = "videoUrl";
  const isValid = !validationMessage.length;
  const isVideoNotSet = page.videoUrl === "" || page.videoUrl === null;
  // can't use displayWarning to disable submit because then we will disable valid urls as well
  const showPlaceholder = !isValid || textState === "" || !videoLoaded;

  const getActiveFontWeight = (condition: boolean): number | string =>
    condition
      ? typedTheme.typography.fontWeightHeavy || 700
      : typedTheme.typography.fontWeightHeavy || 700;

  const handleRemoveVideo = () => {
    setTextState("");
  };

  useEffect(() => {
    setUrlState(debounceValue);
  }, [debounceValue]);

  return (
    <Editable
      fullWidth
      fieldName={fieldName}
      isValid={isValid}
      tooltip="Edit YouTube"
      editNode={
        <>
          <Box>
            <TabRow>
              <TabButton
                $active={activeTab === "custom"}
                onClick={() => setActiveTab("custom")}
              >
                <ColumnSpaceAround>
                  <RowSpaceAround>
                    <Badge
                      size="xs"
                      backgroundColor="textLight"
                      textColor="primary"
                      borderColor="disabled"
                    >
                      <IconFa faIcon={faYoutube} />
                    </Badge>
                  </RowSpaceAround>
                  <Text
                    textAlign="center"
                    margin="xxs"
                    textWeight={getActiveFontWeight(activeTab === "custom")}
                  >
                    Upload your own video
                  </Text>
                </ColumnSpaceAround>
              </TabButton>
              <TabButton
                $active={activeTab === "default"}
                onClick={() => setActiveTab("default")}
              >
                <ColumnSpaceAround>
                  <RowSpaceAround>
                    <Badge
                      size="xs"
                      backgroundColor="textLight"
                      textColor="primary"
                      borderColor="disabled"
                    >
                      <IconFa faIcon={faVideo} />
                    </Badge>
                  </RowSpaceAround>
                  <Text
                    textAlign="center"
                    margin="xxs"
                    textWeight={getActiveFontWeight(activeTab === "default")}
                  >
                    Our videos
                  </Text>
                </ColumnSpaceAround>
              </TabButton>
            </TabRow>
          </Box>
          {activeTab === "custom" && (
            <Box marginBottom="none">
              <EditableVideoUrlCustom
                textState={textState}
                setTextState={setTextState}
                validationMessage={validationMessage}
                setValidationMessage={setValidationMessage}
              />
            </Box>
          )}
          {activeTab === "default" && (
            <Box marginBottom="l">
              <EditableVideoUrlDefault
                textState={textState}
                setTextState={setTextState}
              />
            </Box>
          )}
          <Text textWeight={700}>Video preview</Text>
          <VideoWrapper $showPlaceholder={showPlaceholder}>
            {showPlaceholder ? <FundraisingPageVideoPlaceholder /> : null}
            <ResponsiveReactPlayer
              onReady={() => setVideoLoaded(true)}
              onError={() => setVideoLoaded(false)}
              url={urlState}
              width="100%"
              height="auto"
              config={{
                youtube: {
                  playerVars: {
                    origin: "https://www.youtube.com",
                  },
                },
              }}
            />
          </VideoWrapper>
          {!showPlaceholder && (
            <Box marginBottom="s">
              <Button
                onClick={() => handleRemoveVideo()}
                appearance="tertiary"
                size="m"
                data-cta-type="remove-video"
              >
                Remove Video
              </Button>
            </Box>
          )}
        </>
      }
      viewNode={
        <div>
          {isVideoNotSet ? (
            <AddYouTubeVideoButton />
          ) : (
            <Box paddingTop="l">
              <ResponsiveReactPlayer
                url={page.videoUrl || ""}
                width="100%"
                height="auto"
                config={{
                  youtube: {
                    playerVars: {
                      origin: "https://www.youtube.com",
                    },
                  },
                }}
              />
            </Box>
          )}
        </div>
      }
      handleSubmit={async () => {
        setVideoLoaded(false);
        await handleEditData(
          {
            [`${fieldName}`]: textState.trim().length
              ? textState.trim().replace(/http/i, "http")
              : null,
          },
          false,
        ).catch((err) => {
          trackError(err as Error, { component: "EditableVideoUrl" });
        });
      }}
    />
  );
};

export default EditableVideoUrlForm;
