import React, { useEffect, useState } from "react";
import { useRouter } from "next/compat/router";
import {
  Box,
  Button,
  Heading,
  Text,
  Collapse,
  Modal,
} from "@cruk/cruk-react-components";

import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";
import { fetcher, FWS_BASE_URL } from "@fwa/src/services/apiClient";
import { useFundraiserContext } from "@fwa/src/contexts/FundraiserContext";
import { useModalContext } from "@fwa/src/contexts/ModalContext";
import { getTokenFromAuthCode, logIn, challenge } from "@fwa/src/services/auth";
import { getLoggedInFundraiser } from "@fwa/src/services/fundraiserService";

import { ChangePasswordForm } from "@fwa/src/components/ChangePasswordForm";

import { RowCenter } from "@fwa/src/components/styles";
import {
  PartyPopper,
  MessageWrapper,
} from "@fwa/src/components/ActivityManagementJourney/styles";

import {
  type FinaliseRegistrationType,
  type FundraiserType,
  type FundraisingPageType,
  type OneTimeLoginType,
} from "@fwa/src/types";

const OAUTH_CLIENT_ID = process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID || "";
const OAUTH_BASE_URL = process.env.NEXT_PUBLIC_OAUTH_BASE_URL || "";

type Props = {
  page: FundraisingPageType;
};

export const ActivityManagementJourney = ({ page }: Props) => {
  const { trackError, sendTrackingEvent } = useTrackingContext();
  const [, setModalState] = useModalContext();
  const [fundraiserState, setFundraiserState] = useFundraiserContext();

  const modalPartyPopper = "/assets/images/img/party-popper.svg";

  const router = useRouter();
  const {
    message_header: messageHeader,
    message_body: messageBody,
    signature,
    ...query
  } = router?.query || {};
  const [finaliseRegistration, setFinaliseRegistration] = useState<
    FinaliseRegistrationType | undefined
  >();
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  const [hasRegFailed, setHasRegFailed] = useState<boolean>(false);

  // Before AM updates their backend infrastructure the message header and body are still base64 encoded and gzipped
  // for this reason until the update to using plain strings in the query string will will use some default text
  // message_signature is deprecated in the new API, if it exists we are seeing the legacy AM API.
  const isAMLegacy = typeof query.message_signature !== "undefined";
  const defaultHeader = `Congratulations! You're now signed up.`;
  const defaultBody = `Personalise your page to let everyone know why you're raising money and share it with your friends and family to encourage donations. Why not be the first to add a donation to your page? It could encourage others to do the same!`;
  const header = isAMLegacy ? defaultHeader : messageHeader || "";
  const body = isAMLegacy ? defaultBody : messageBody || "";

  const { loggedInStatus, fundraiser, tempPassword } = fundraiserState;

  const loggedInAsNonRegisteredUser =
    page?.fundraiser &&
    fundraiser &&
    fundraiser?.uniqueId !== page.fundraiser.uniqueId;
  const showLoginLink =
    loggedInStatus === "loggedOut" || loggedInAsNonRegisteredUser;

  const dismissModal = () => {
    router
      ?.push({ query }, undefined, {
        shallow: true,
      })
      .then(() => setModalState(null))
      .catch((err) => {
        trackError(err as Error, { component: "ActivityManagementJourney" });
      });
  };

  // first complete registration
  useEffect(() => {
    if (
      signature &&
      !isRegistering &&
      (!finaliseRegistration || !hasRegFailed)
    ) {
      setIsRegistering(true);
      // get registration details from signature
      (async () => {
        const registered: boolean | void = await fetcher(
          `${FWS_BASE_URL}/fundraisingpages/${page.uniqueId}/finaliseactivityregistration`,
          {
            method: "POST",
            body: JSON.stringify({
              codeChallenge: challenge(),
              clientId: OAUTH_CLIENT_ID,
              signature,
            }),
          },
        )
          .then((res) => res as FinaliseRegistrationType)
          .then((activityRegistration: FinaliseRegistrationType) => {
            sendTrackingEvent({
              event: "am_signup_confirmation",
              activityName: page.activityName,
            });
            setFinaliseRegistration(activityRegistration);
            return undefined;
          })
          .then(() =>
            router?.push(
              {
                pathname: router.pathname,
                query: { ...router.query, signature: undefined },
              },
              undefined,
              { shallow: true },
            ),
          )
          .then(() => {
            setIsRegistering(false);
            return true;
          })
          .catch((err) => {
            trackError(err as Error, {
              component: "ActivityManagementJourney",
            });
            setIsRegistering(false);
            setHasRegFailed(true);
          });

        if (!registered) {
          setHasRegFailed(true);
        }
      })().catch((err) => {
        trackError(err as Error, { component: "ActivityManagementJourney" });
      });
    }
  }, [router?.isReady]);

  // if registered, attempt to log in as registered user, show activity management modal
  useEffect(() => {
    (async () => {
      // try logging in if onetime login exists
      if (finaliseRegistration?.oneTimeLogin) {
        const oneTimeLogin: OneTimeLoginType | void = await fetcher(
          `${OAUTH_BASE_URL}${finaliseRegistration.oneTimeLogin.urlPath}?noninteractive=true`,
          {
            credentials: "include",
          },
        )
          .then((res) => res as OneTimeLoginType)
          .catch((err) => {
            trackError(err as Error, {
              component: "ActivityManagementJourney",
            });
          });

        if (oneTimeLogin) {
          await getTokenFromAuthCode(oneTimeLogin.authCode);

          const loggedInFundraiser: FundraiserType | void | null =
            await getLoggedInFundraiser()
              .then((res) => res)
              .catch((err) => {
                trackError(err as Error, {
                  component: "ActivityManagementJourney",
                });
              });

          if (loggedInFundraiser) {
            setFundraiserState({
              ...fundraiserState,
              fundraiser: loggedInFundraiser,
              loggedInStatus: "loggedIn",
              tempPassword: finaliseRegistration.oneTimeLogin?.password || null,
            });
          }
        }
      }
    })().catch((err) => {
      trackError(err as Error, { component: "ActivityManagementJourney" });
    });

    if (
      loggedInStatus !== "unknown" &&
      (finaliseRegistration || hasRegFailed)
    ) {
      setModalState(
        <Modal
          closeFunction={() => setModalState(null)}
          modalName="page infomation"
        >
          <MessageWrapper data-component="am-modal">
            <Heading textAlign="center">{header}</Heading>
            <PartyPopper
              alt=""
              width="90px"
              height="90px"
              src={modalPartyPopper}
            />
            <Text textAlign="center">{body}</Text>

            {fundraiser && tempPassword ? (
              <>
                <Text textAlign="center">
                  Please create a password to continue.
                </Text>
                <ChangePasswordForm
                  onSuccess={dismissModal}
                  onCancel={dismissModal}
                  cancelButtonText="Remind me later"
                  submitButtonText="Submit"
                />
                <Box marginTop="m">
                  <Collapse
                    headerTitleText="Why are we asking for this?"
                    id="password-explanation"
                  >
                    <Text marginBottom="m">
                      {`We've created a personalised Giving Page for you which
                      will help you fundraise for your event.`}
                    </Text>
                    <Text marginBottom="m">
                      {` As there wasn't an existing account linked to the email
                      address provided, we've created this one for you. It's
                      important that you create a password now, so you can
                      easily log in to manage your Giving Page during your
                      fundraising.`}
                    </Text>
                  </Collapse>
                </Box>
              </>
            ) : (
              <>
                {showLoginLink && (
                  <Box marginBottom="xs">
                    <RowCenter>
                      <Button
                        onClick={() => {
                          router
                            ?.push({ query }, undefined, {
                              shallow: false,
                            })
                            .then(() => {
                              logIn();
                              return undefined;
                            })
                            .catch((err) => {
                              trackError(err as Error, {
                                component: "ActivityManagementJourney",
                              });
                            });
                        }}
                        data-cta-type="log-in"
                      >
                        Log in to edit page
                      </Button>
                    </RowCenter>
                  </Box>
                )}
                <Box marginBottom="xs">
                  <RowCenter>
                    <Button
                      appearance={showLoginLink ? "secondary" : "primary"}
                      onClick={dismissModal}
                      data-cta-type="dismiss-modal"
                    >
                      Explore my page
                    </Button>
                  </RowCenter>
                </Box>
              </>
            )}
          </MessageWrapper>
          ,
        </Modal>,
      );
    }
  }, [finaliseRegistration, hasRegFailed, loggedInStatus]);

  return null;
};

export default ActivityManagementJourney;
