import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import NextLink from "next/link";
import { Heading, Text, Box, Button } from "@cruk/cruk-react-components";

import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";
import { TotalsSection } from "@fwa/src/components/TotalsSection";

import { Border } from "@fwa/src/components/styles";

import { type FundraisingPageType } from "@fwa/src/types";

const FeedListFilter = dynamic(
  () => import("@fwa/src/components/FeedListFilter"),
);

type Props = {
  canEdit: boolean;
  feedInView: boolean;
  isStravaMetric: boolean;
  fitbitDailyTargetSteps: number;
  firstName: string;
  url: string | null;
  page: FundraisingPageType;
};

export const FundraisingPageLatestUpdates = ({
  page,
  canEdit,
  feedInView,
  isStravaMetric,
  fitbitDailyTargetSteps,
  firstName,
  url,
}: Props) => {
  const displayNameOrUserName =
    page.owner?.displayName !== undefined ? page.owner?.displayName : firstName;
  const { sendTrackingEvent } = useTrackingContext();
  const [feedItemTotals, setFeedItemTotals] = useState({
    feeditems:
      0 +
      page.donationsCount +
      page.postsCount +
      (page?.milestonesCount || 0) +
      (page?.strava?.count || 0) +
      (page?.fitbit?.count || 0),
    donations: page.donationsCount,
    posts: page.postsCount,
    activitys: 0 + (page?.strava?.count || 0) + (page?.fitbit?.count || 0),
    milestones: page?.milestonesCount || 0,
  });

  useEffect(() => {
    if (page) {
      const newTotals = {
        feeditems:
          0 +
          page.donationsCount +
          page.postsCount +
          (page?.milestonesCount || 0) +
          (page?.strava?.count || 0) +
          (page?.fitbit?.count || 0),
        donations: page.donationsCount,
        posts: page.postsCount,
        activitys: 0 + (page?.strava?.count || 0) + (page?.fitbit?.count || 0),
        milestones: page?.milestonesCount || 0,
      };
      setFeedItemTotals(newTotals);
    }
  }, [page]);

  return (
    <Box backgroundColor="lightBackground" marginBottom="m">
      <Heading h2 textSize="xl">
        Latest updates
      </Heading>
      {feedInView && (
        <FeedListFilter
          pageId={page.uniqueId}
          canCreate={canEdit}
          feedItemTotals={feedItemTotals}
          setFeedItemTotals={setFeedItemTotals}
          pageUrl={url}
          isStravaMetric={isStravaMetric}
          fitbitDailyTargetSteps={fitbitDailyTargetSteps}
        />
      )}

      {!page.donationsCount && page.pageStatus !== "closed" && (
        <Box marginTop="s" data-component="latest-updates">
          <Border>
            <Text textAlign="center">{`${displayNameOrUserName} needs your support. Be the first to make a donation.`}</Text>
            <NextLink
              href={`/donate/${page.uniqueId}/details`}
              onClick={() => {
                sendTrackingEvent({
                  event: "donate_cta",
                  cta: "first donation",
                });
              }}
              data-cta-type="donate"
            >
              <Button appearance="primary" full as="span">
                Donate
              </Button>
            </NextLink>
          </Border>
        </Box>
      )}
      <TotalsSection
        donationsTotalAmount={page.donationsTotalAmount}
        donationsTotalAmountFake={page.donationsTotalAmountFake}
        donationsTotalAmountFacebook={page.donationsTotalAmountFacebook}
      />
    </Box>
  );
};

export default FundraisingPageLatestUpdates;
