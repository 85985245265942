import styled from "styled-components";
import dynamic from "next/dynamic";

const ReactPlayer = dynamic(() => import("react-player"), { ssr: false });

export const ResponsiveReactPlayer = styled(ReactPlayer)`
  aspect-ratio: 16 / 9;
`;

export default ResponsiveReactPlayer;
