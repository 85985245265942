import styled from "styled-components";
import { Button, type ThemeType } from "@cruk/cruk-react-components";

const transitionDurationSeconds = 0.5;

export const CollapseButton = styled(Button)<{ theme: ThemeType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  margin-bottom: 0;
  height: initial;
  text-decoration: none;
  text-align: left;
  border-radius: 0;
  && :focus {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const Flipper = styled.div<{ $open: boolean; theme: ThemeType }>`
  transform: ${({ $open }) =>
    $open ? "translateY(0.1em) scaleY(-1)" : "initial"};
  transition-duration: ${transitionDurationSeconds}s;
  color: ${({ theme }) => theme.colors.primary};
  margin-left: ${({ theme }) => theme.spacing.xxs};
`;

export const CollapseContent = styled.div<{
  theme: ThemeType;
  $openStatus: boolean;
  $contentHeight: string;
  $widenContentBy: string;
}>`
  transition: ${transitionDurationSeconds}s ease;
  max-height: ${({ $openStatus, $contentHeight }) =>
    $openStatus ? `${$contentHeight}` : "1px"};
  visibility: ${({ $openStatus }) => ($openStatus ? "visible" : "hidden")};
  overflow: hidden;
  margin: ${({ $widenContentBy }) =>
    $widenContentBy.length ? `0 -${$widenContentBy}` : "0"};
`;
