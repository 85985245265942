import dynamic from "next/dynamic";

import { type FundraisingPageType } from "@fwa/src/types";

const FundraisingPageConnections = dynamic(
  () => import("@fwa/src/components/FundraisingPageConnections"),
);
const FundraisingPageProgress = dynamic(
  () => import("@fwa/src/components/FundraisingPageProgress"),
);

type Props = {
  page: FundraisingPageType;
  mutatePage: (
    data: Partial<FundraisingPageType>,
  ) => Promise<undefined | void | FundraisingPageType>;
};

export const FundraisingPageAdmin = ({ page, mutatePage }: Props) => {
  const shouldShowPageConnections =
    page?.fitbitAllowed ||
    page?.facebookFundraiserAllowed ||
    page?.stravaAllowed ||
    false;

  return (
    <>
      {/* Personalise your giving page section */}
      <FundraisingPageProgress page={page} />
      {/* Page connections section */}
      {shouldShowPageConnections && (
        <FundraisingPageConnections page={page} mutatePage={mutatePage} />
      )}
    </>
  );
};

export default FundraisingPageAdmin;
