import { useEffect, useState } from "react";
import { ErrorText, Modal, Box } from "@cruk/cruk-react-components";
import { useRouter } from "next/compat/router";
import { useSwrGet } from "@fwa/src/hooks/useSwrGet";

import { queryAsString } from "@fwa/src/utils/urlUtils";
import { fwsUrlFeedItem } from "@fwa/src/services/feedItemService";

import { FeedItem } from "@fwa/src/components/FeedItem";

import { type FeedItemType } from "@fwa/src/types";
import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";

type Props = {
  feedId: string;
  pageId: string;
  pageUrl: string;
  isStravaMetric: boolean;
  fitbitDailyTargetSteps: number;
  sharedFeedItemData?: FeedItemType;
};

export const FeedItemShared = ({
  feedId,
  pageId,
  pageUrl,
  isStravaMetric,
  fitbitDailyTargetSteps,
  sharedFeedItemData,
}: Props) => {
  const { trackError } = useTrackingContext();
  const [showModal, setShowModal] = useState<boolean>(false);
  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { feed, slug, ...restOfQuery } = router?.query || {};
  const feedString = queryAsString(feed);

  const url =
    feedString.length || feedId
      ? `${fwsUrlFeedItem({ feedItemId: feedString || feedId })}`
      : null;

  // page cache
  const opts = { fallbackData: sharedFeedItemData };
  const { data, error, mutate } = useSwrGet<FeedItemType>(url, opts);

  const handleClose = () => {
    router
      ?.push({
        pathname: router.asPath.split("?")[0],
        query: { ...restOfQuery },
      })
      .catch((err) => {
        trackError(err as Error, { component: "FeedItemShared" });
      });
  };

  useEffect(() => {
    setShowModal(!!data);
  }, [data]);

  const mutateOnEdit = (updatedItem: FeedItemType) => {
    mutate(updatedItem).catch((err) => {
      trackError(err as Error, { component: "FeedItemShated" });
    });
  };

  if (showModal) {
    return (
      <Modal
        closeFunction={handleClose}
        showCloseButton
        modalName="shared feeditem"
      >
        <Box marginTop="xxl" padding="xs">
          {!error && data && (
            <FeedItem
              feedItem={data}
              pageUrl={pageUrl}
              pageId={pageId}
              isStravaMetric={isStravaMetric}
              fitbitDailyTargetSteps={fitbitDailyTargetSteps}
              handleDelete={() => {
                // placeholder no op
              }}
              handleEdit={mutateOnEdit}
            />
          )}
          {error ? <ErrorText>Unable to find feed item</ErrorText> : null}
        </Box>
      </Modal>
    );
  }
  return null;
};

export default FeedItemShared;
