import React, { type ChangeEvent, useState } from "react";
import {
  Heading,
  Box,
  ErrorText,
  ProgressBar,
  Text,
  crukTheme,
  TextField,
  IconFa,
} from "@cruk/cruk-react-components";
import { format, parseISO } from "date-fns";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import { useTrackingContext } from "@fwa/src/contexts/TrackingContext";
import { fetcher } from "@fwa/src/services/apiClient";
import { fwsUrlFitbit } from "@fwa/src/services/fitbitService";
import { numberWithCommas } from "@fwa/src/utils/formatUtils";
import { durationFromDateStrings } from "@fwa/src/utils/timeUtils";
import { Editable } from "@fwa/src/components/Editable";
import { OnlyYou } from "@fwa/src/components/OnlyYou";

import {
  MainWrapper,
  TextWrapper,
  RowAligned,
  FitbitSVGWrapper,
  EditIconWrapper,
  FloatingIcon,
} from "@fwa/src/components/FitbitTracker/styles";
import { DashedBorder } from "@fwa/src/components/styles";

import { type FitbitType, type FundraisingPageType } from "@fwa/src/types";

const fitbitSVG = "/assets/images/logos/fitbit.svg";

const calculatePercent = (target: number, total: number) => {
  const percent = (total / target) * 100;
  if (percent === Number.POSITIVE_INFINITY || Number.isNaN(percent)) {
    return 0;
  }
  return Math.floor(percent < 1 ? Math.ceil(percent) : Math.floor(percent));
};

type NonEditingViewProps = {
  canEdit?: boolean;
  totalSteps: number;
  targetSteps?: number | null;
  lastSyncedTime: string;
};

const NonEditingView = ({
  canEdit,
  totalSteps,
  targetSteps,
  lastSyncedTime,
}: NonEditingViewProps) => (
  <Box margin="none" paddingRight="xs">
    <RowAligned>
      <FitbitSVGWrapper>
        <img src={fitbitSVG} alt="" />
      </FitbitSVGWrapper>
      <Heading h2 marginRight="xs" marginVertical="none" textSize="xl">
        Fitbit activity tracker
      </Heading>
    </RowAligned>
    <MainWrapper>
      <ProgressBar
        circleSize="110px"
        barColor={crukTheme.tokenColors.cyan_900}
        circleContents={
          <Box>
            <Text
              textAlign="center"
              textSize="l"
              marginBottom="none"
              marginHorizontal="xs"
            >
              {numberWithCommas(totalSteps)}
            </Text>
            <Text textAlign="center">Total steps</Text>
          </Box>
        }
        percentage={targetSteps ? calculatePercent(targetSteps, totalSteps) : 0}
        isCircular
      />
      {targetSteps ? (
        <TextWrapper>
          <Text
            textAlign="center"
            textSize="xl"
            marginLeft="xs"
            marginBottom="none"
          >
            {`${calculatePercent(targetSteps, totalSteps)}%`}
          </Text>
          <Text
            marginLeft="xs"
            marginRight="m"
            marginBottom="none"
          >{` of ${numberWithCommas(targetSteps)} step target`}</Text>
          {canEdit && (
            <FloatingIcon>
              <IconFa faIcon={faPenToSquare} />
            </FloatingIcon>
          )}
        </TextWrapper>
      ) : (
        <Text marginHorizontal="xs" marginBottom="none">
          {`Total steps taken ${numberWithCommas(totalSteps)}`}
        </Text>
      )}
    </MainWrapper>
    <Box marginTop="xxs">
      <Text textAlign="right">{lastSyncedTime}</Text>
    </Box>
  </Box>
);

type Props = {
  canEdit: boolean;
  fitbit: FitbitType;
  mutatePage: (
    data: Partial<FundraisingPageType>,
  ) => Promise<undefined | void | FundraisingPageType>;
};

export const FitbitTracker = ({ fitbit, mutatePage, canEdit }: Props) => {
  const { trackError } = useTrackingContext();
  const { startDate, endDate, targetSteps, totalSteps } = fitbit;
  const duration = durationFromDateStrings(startDate, endDate);

  const [targetStepsState, setTargetStepsState] = useState<number>(
    targetSteps || 0,
  );
  const [targetStepsDaily, setTargetStepsDaily] = useState(
    Math.ceil((targetSteps || 0) / duration),
  );

  const [submissionErrorMessage, setSubmissionErrorMessage] =
    useState<string>("");

  // parse ISO of fitbit.updated if present then format according to style guidline (9 February 2024 at 5.03pm) and lower case am/pm
  // we're doing this all in one variable because fitbit.updated could be undefined so we don't have to check it's type multiple times this way
  const lastSyncedString = fitbit?.updated
    ? `Last synced: ${format(
        parseISO(fitbit.updated),
        "d MMMM yyyy 'at' h.mm aaa",
      )
        .replace(" am", "am")
        .replace(" pm", "pm")}`
    : "";

  const handleSubmit = async () => {
    setSubmissionErrorMessage("");
    const newFitbit: FitbitType | void = await fetcher(
      fwsUrlFitbit({ fitbitId: fitbit.uniqueId }),
      {
        method: "PATCH",
        body: JSON.stringify({ targetSteps: targetStepsState }),
      },
    )
      .then((res) => res as FitbitType)
      .catch((err) => {
        trackError(err as Error, { component: "FitbitTracker" });
        setSubmissionErrorMessage("Unable to update target");
      });

    if (newFitbit) {
      mutatePage({ fitbit: newFitbit }).catch((err) => {
        trackError(err as Error, { component: "FitbitTracker" });
      });
    }
  };

  const handleOverallTargetChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value: number = parseFloat(e.target.value) || 0;
    setTargetStepsState(Math.round(+value * 10) / 10);
    const dailyTarget = Math.ceil(value / duration);
    setTargetStepsDaily(Math.round(+dailyTarget * 10) / 10);
  };

  const handleDailyTargetChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value: number = parseFloat(e.target.value) || 0;
    setTargetStepsDaily(Math.round(+value * 10) / 10);
    const overAllTarget = Math.ceil(value * duration);
    setTargetStepsState(Math.round(+overAllTarget * 10) / 10);
  };

  if (!canEdit)
    return (
      <NonEditingView
        canEdit={canEdit}
        totalSteps={totalSteps}
        targetSteps={targetSteps}
        lastSyncedTime={lastSyncedString}
      />
    );

  return (
    <Editable
      isValid
      editNode={
        <Box paddingRight="xs">
          <RowAligned>
            <FitbitSVGWrapper>
              <img src={fitbitSVG} alt="" />
            </FitbitSVGWrapper>
            <Heading h2 marginRight="xs" marginVertical="none" textSize="xl">
              Fitbit activity tracker
            </Heading>
          </RowAligned>
          <MainWrapper>
            <ProgressBar
              circleSize="110px"
              barColor={crukTheme.tokenColors.cyan_900}
              circleContents={
                <Box>
                  <Text textAlign="center" textSize="l" marginBottom="none">
                    {numberWithCommas(totalSteps)}
                  </Text>
                  <Text textAlign="center">Total steps</Text>
                </Box>
              }
              percentage={
                targetStepsState
                  ? calculatePercent(targetStepsState, totalSteps)
                  : 0
              }
              isCircular
            />
            {targetStepsState ? (
              <TextWrapper>
                <Text
                  textAlign="center"
                  textSize="xl"
                  marginLeft="xs"
                  marginBottom="none"
                >
                  {`${calculatePercent(targetStepsState, totalSteps)}%`}
                </Text>
                <Text marginHorizontal="xs">{` of ${numberWithCommas(
                  targetStepsState,
                )} step target`}</Text>
              </TextWrapper>
            ) : (
              <Text marginHorizontal="xs">
                {`Total steps taken ${numberWithCommas(totalSteps)}`}
              </Text>
            )}
          </MainWrapper>
          <Box paddingTop="s">
            <Text textWeight={700}>Step target</Text>
            <Text>Choose how many steps you want to complete.</Text>

            <Box>
              <TextField
                type="number"
                label="Daily"
                aria-label="Set a daily step target"
                onChange={handleDailyTargetChange}
                value={String(targetStepsDaily)}
              />
            </Box>
            <Box>
              <TextField
                type="number"
                label="Overall"
                aria-label="Set an overall step target"
                onChange={handleOverallTargetChange}
                value={String(targetStepsState)}
              />
            </Box>
            {submissionErrorMessage && (
              <ErrorText>{submissionErrorMessage}</ErrorText>
            )}
          </Box>
        </Box>
      }
      viewNode={
        <>
          <NonEditingView
            canEdit={canEdit}
            totalSteps={totalSteps}
            targetSteps={targetSteps}
            lastSyncedTime={lastSyncedString}
          />

          {!targetStepsState && (
            <Box marginTop="s" marginBottom="none">
              <DashedBorder>
                <OnlyYou />

                <Text marginBottom="none">Add a challenge target</Text>
                <EditIconWrapper>
                  <IconFa faIcon={faPenToSquare} />
                </EditIconWrapper>
              </DashedBorder>
            </Box>
          )}
        </>
      }
      handleSubmit={handleSubmit}
      fieldName="fitbit target steps"
      tooltip="Edit fitbit target steps"
      editButtonColor="rgba(255,255,255,0)"
    />
  );
};

export default FitbitTracker;
