import { type ThemeType } from "@cruk/cruk-react-components";
import styled from "styled-components";

export const MainWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const RowAligned = styled.div<{ theme: ThemeType }>`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.s};
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
`;

export const FitbitSVGWrapper = styled.div<{ theme: ThemeType }>`
  border-radius: 50%;
  height: ${({ theme }) => theme.spacing.m};
  width: ${({ theme }) => theme.spacing.m};
  margin-right: ${({ theme }) => theme.spacing.xxs};
`;

export const EditIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 2px;
`;

export const FloatingIcon = styled.div`
  position: absolute;
  right: 0;
`;
